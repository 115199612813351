$primary-color: #f59211;
$secondary-color: black;

$background-color: white;

$danger-color: rgb(221, 33, 0);
$sucess-color: green;
$warning-color: orange;
$info-color: rgb(2, 130, 235);

$font-color: black;
$border-color: #e0e0e0;

:export {
    primaryColor: #f59211;
    secondaryColor: black;
    backgroundColor: white;
    dangerColor: tomato;
    sucessColor: green;
    warningColor: orange;
    infoColor: rgb(2, 130, 235);
    fontColor: black;
    borderColor: #e0e0e0;
}
